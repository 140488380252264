import './styles.css';

const Match = ({hurdleCards, hurdleCardCount, sortPositioning}) => {
  return (
    <div className="game-board">
      <div className="game-grid-container">
        <div className="game-grid">
          <div className="hurdle-cards">
            {hurdleCards.map((hc, hcIdx) => (
                hurdleCardCount > hcIdx ?
                (
                <>
                <img src={process.env.PUBLIC_URL + `/cards/PNG/${hc}.png`} className={'hurdle-card card-back-rotate row-' + hcIdx} key={hcIdx} alt="Card"/>
                <img src={process.env.PUBLIC_URL + '/cards/CardBack.png'} className={'hurdle-card card-front-rotate row-' + hcIdx} key={hcIdx} alt="Card back"/>
                </>
                )
                
                :
                <img src={process.env.PUBLIC_URL + '/cards/CardBack.png'} className={'hurdle-card row-' + hcIdx} key={hcIdx} alt="Card back"/>
            ))}
          </div>
          <div className="race-track">
          <img src={process.env.PUBLIC_URL + `/cards/PNG/AH.png`} className={'card-ace row-' + sortPositioning.AH} alt="Ace of hearts"/>
          </div>
          <div className="race-track">
            <img src={process.env.PUBLIC_URL + `/cards/PNG/AS.png`} className={'card-ace row-' + sortPositioning.AS} alt="Ace of spades"/>
           </div>
           <div className="race-track">
            <img src={process.env.PUBLIC_URL + `/cards/PNG/AD.png`} className={'card-ace row-' + sortPositioning.AD} alt="Ace of diamonds"/>
          </div>
          <div className="race-track">
          <img src={process.env.PUBLIC_URL + `/cards/PNG/AC.png`} className={'card-ace row-' + sortPositioning.AC} alt="Ace of clubs"/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Match;