import { useEffect, useRef } from "react";
import "./styles.css";
import PhoneImage from "../../resources/ChatSmall.png";
import PhoneFingerprints from "../../resources/PhoneFingerprints.png";

const Phone = ({
  phoneUp,
  setPhoneUp,
  message,
  sendMessage,
  messages,
  setMessage,
}) => {
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (message !== "") {
        sendMessage();
      }
    }
  };
  useEffect(() => {
    document.querySelector(".messages").scrollTop =
      document.querySelector(".messages").scrollHeight;
  }, [messages]);

  return (
    <div className={"phone " + (phoneUp ? "phone-down" : "")}>
      <img
        src={PhoneImage}
        className="phone-image"
        onClick={() => setPhoneUp()}
        alt="Phone"
      />
      <img src={PhoneFingerprints} className="phone-fingerprints" />
      <div className="phone-content">
        <div className="messages">
          {messages.map((message, mIdx) => (
            <div className="message" key={mIdx}>
              <span className="time-sent">{`${
                (new Date(message.date).getHours() < 10 ? "0" : "") +
                new Date(message.date).getHours()
              }:${
                (new Date(message.date).getMinutes() < 10 ? "0" : "") +
                new Date(message.date).getMinutes()
              }`}</span>
              <span className="user"> {message.username}: </span>
              <span
                className="message-text"
                style={{ color: message.color ? message.color : "#999999" }}
              >
                {message.message}
              </span>
            </div>
          ))}
        </div>
        <div className="message-input">
          <input
            type="text"
            placeholder="Type your message here..."
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyPress={(e) => handleKeyDown(e)}
            className="chat-input"
          />
        </div>
      </div>
    </div>
  );
};

export default Phone;
