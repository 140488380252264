import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addSession } from '../../actions/sessionActions';
import './styles.css';
import BackgroundNewer from '../../resources/BackgroundNewer.png';
import Front from '../../resources/Front.png';
import GameBoard from '../../resources/BackScreen.png'
import Kalli from '../../resources/KalliFinal.gif';
import Cat from '../../resources/cat.gif';
import Critter from '../../resources/Critter.png';
import OverShadows from '../../resources/OverShadows.png';
import Exit from '../../resources/Exit.png';
import { useHistory, useParams } from 'react-router';

const WelcomeView = ({ history }) => {
  const search = history.location.search;
  const params = new URLSearchParams(search);
  const room = params.get('room');
  const socket = useSelector(({ socket }) => socket);
  const session = useSelector(({ session }) => session);
  const dispatch = useDispatch();
  const [username, setUsername] = useState('');
  const [userAction, setUserAction] = useState('join');
  const [roomCode, setRoomCode] = useState(room || '');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    socket.on('session', session => {
      dispatch(addSession(session));
      if (session.isHost) {
        //Create match
        socket.emit('create_game', session.matchId, session.username, session.userID);
      }
      else if (!session.isHost && session.matchId.length > 0) {
        socket.emit('join_game', session.matchId);
        history.push('/room/' + session.matchId)
      }
    });
    socket.on('move_to_game', matchId => {
      socket.emit('join_game', matchId);
      history.push('/room/' + matchId)
    })
    return () => {
      socket.off('session');
      socket.off('connect_error');
      socket.off('move_to_game');
    }
  }, [])
  
  const generateMatchId = () => {
    var matchId = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    for (var i = 0; i < 7; i++) {
      matchId += characters.charAt(Math.floor(Math.random() * characters.length))
    }
    return matchId;
  }

  const onChooseUsername = () => {
    
    if(userAction === 'host') {
      // Create unique match id
      const matchId = generateMatchId();
      socket.auth = { username: username, isHost: true, matchId: matchId };
    }
    else {
      socket.auth = { username: username, isHost: false, matchId: roomCode,};
    }
    socket.connect();

    socket.on("connect_error", err => {
      if (err.message === "occupied") {
        setErrorMessage('Username already taken!');
      }
      if (err.message === "invalid username") {
        setErrorMessage('Invalid username!');
      }
      if (err.message === "no match") {
        setErrorMessage('Invalid room code!');
      }
    });
  };
  console.log(userAction)
  return (
    <div className="welcome-view-container container">
      <div className="overlay">
        <img src={Cat} className="cat" alt="Cat"/>
        <img src={Critter} className="critter" alt="Critter"/>
        <img src={OverShadows} className="shadows" alt="Shadows"/>
        <img src={BackgroundNewer} className="main-background" alt="Background"/>
        <img src={Front} className="front" alt="Front"/>
        <img src={Kalli} className="kalli" alt="Kalli the shopkeeper"/>
        <img src={GameBoard} className="game-board-background" alt="Gameboard"/>
        <img src={Exit} className="exit" alt="Exit"/>
      </div>
      <div className="full-tablet">
        <div className="full-tablet-content">
          <div className="join-container">
            <div className="action-buttons">
              <div onClick={() => setUserAction('join')} className={"join-button " + (userAction === 'join' ? 'join-button-selected' : '')}>Join a game</div>
              <div onClick={() => setUserAction('host')} className={"join-button " + (userAction === 'host' ? 'join-button-selected' : '')}>Host a game</div>
            </div>
            <div className="join-input-container">
              <input id="username" name="username" type="text" placeholder="Username" value={username}  onChange={e => setUsername(e.target.value)} className="welcome-input" />
              {
              userAction === 'join' && 
              <input id="room-code" name="room-code" type="text" placeholder="Room Code" value={roomCode} onChange={e => setRoomCode(e.target.value)} className="welcome-input" />
              }
              <button className="join-button join-button-selected" onClick={() => onChooseUsername()}>{userAction === 'join' ? 'Join' : 'Create'}</button>
              {errorMessage && ( <p className="error"> {errorMessage} </p> )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default WelcomeView;