import './styles.css'
const WinnerModal = ({sipsGiven, sipsToGiveAmount, playersToSip, updatePlayerSipCount, confirmSips, setShowGiveSipModal}) => {
  return (
    <div className="winner-screen">
      <span className="tablet-title">YOU WON!</span>
      <span className="tablet-title">You have given {sipsGiven}/{sipsToGiveAmount} sips.</span>
      {
      playersToSip.map((p) => (
        <div className="player-to-give" key={p.userId}>
          <span className="tablet-title">{p.username}</span>
          <button className="sip-button" onClick={() => updatePlayerSipCount(p.userId, -1)}>-1</button>
          <button className="sip-button" onClick={() => updatePlayerSipCount(p.userId, -5)}>-5</button>
          <button className="sip-button" onClick={() => updatePlayerSipCount(p.userId, -10)}>-10</button>
          <span className="tablet-title">({p.sips})</span>
          <button className="sip-button" onClick={() => updatePlayerSipCount(p.userId, 1)}>+1</button>
          <button className="sip-button" onClick={() => updatePlayerSipCount(p.userId, 5)}>+5</button>
          <button className="sip-button" onClick={() => updatePlayerSipCount(p.userId, 10)}>+10</button>
        </div>
      ))
      }
      <button className="confirm-bet-button" onClick={() => confirmSips()} disabled={sipsGiven !== sipsToGiveAmount}>Confirm</button>
    </div>
 )
}

export default WinnerModal;