import { useEffect, useRef } from 'react';
import './styles.css'

const RecentCards = ({recentCards}) => {

  useEffect(() => {
    document.querySelector('.recent-cards-container').scrollTop = document.querySelector('.recent-cards-container').scrollHeight
  }, [recentCards])

  return (
    <div className="recent-cards-container">
      {recentCards.map((card, cIdx) => (
        <img src={process.env.PUBLIC_URL + `/cards/PNG/${card}.png`} className="recent-card" key={cIdx} alt="Recent card"/>
      ))}
    </div>
  )
}

export default RecentCards;