import { Switch, Route } from 'react-router-dom';
import WelcomeView from './views/WelcomeView';
import MatchView from './views/MatchView'
import HasSession from './components/HasSession';
import "./styles/index.css";

const App = () => (
  <Switch>
    <Route exact path="/" component={ WelcomeView } />
    <Route exact path="/room/:id" component={ MatchView } />
  </Switch>
);

export default App;