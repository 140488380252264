import { useEffect } from 'react';
import './styles.css';

const Players = ({players}) => {
  const showBet = (bets) => {
    if (bets.amount === 1) {
      return <img src={process.env.PUBLIC_URL + `/cards/PNG/A${bets.sort}.png`} className="small-card" alt="Ace"/>
    }
    else if (bets.amount === 11) {
      return <img src={process.env.PUBLIC_URL + `/cards/PNG/J${bets.sort}.png`} className="small-card" alt="Jack"/>
    }
    else if (bets.amount === 12) {
      return <img src={process.env.PUBLIC_URL + `/cards/PNG/Q${bets.sort}.png`} className="small-card" alt="Queen"/>
    }
    else if (bets.amount === 13) {
      return <img src={process.env.PUBLIC_URL + `/cards/PNG/K${bets.sort}.png`} className="small-card" alt="King"/>
    }
    else if (bets.amount > 13) {
      if (bets.sort === "H") {
        return `(${bets.amount} ❤️)`
      }
      else if (bets.sort === "C") {
        return `(${bets.amount} ♣️)`
      }
      else if (bets.sort === "D") {
        return `(${bets.amount} ♦️)`
      }
      else if (bets.sort === "S") {
        return `(${bets.amount} ♠️)`
      }
    }
    else {
      return <img src={process.env.PUBLIC_URL + `/cards/PNG/${bets.amount + bets.sort}.png`} className="small-card" alt="Card"/>
    }
  }

  return (
    <div className="player-list">
      { players !== undefined && players.length > 0 && players.map(p => (
        <div className="player" key={p.userId}>
          <div className="player-details">
            <img src={`https://robohash.org/${p.username}`} className="user-avatar" alt="Avatar"/>
            <div className="player-names">
              <div className="match-player">{p.username}</div>
              <div className="player-type">{p.isHost ? 'host' : 'guest'}</div>
            </div>
          </div>
          <div className="bet">{p.bets.hasPlacedBet ? showBet(p.bets) : "❌"}</div>
        </div>
      ))}
    </div>
  )
}

export default Players;