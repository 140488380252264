import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import "./styles.css";
import Tablet from "../../components/Tablet";
import Navigation from "../../components/Navigation";
import RecentCards from "../../components/RecentCards";
import Match from "../../components/Match";
import Phone from "../../components/Phone";
import Background from "../../resources/BackgroundNewer.png";
import Front from "../../resources/Front.png";
import GameBoard from "../../resources/BackScreen.png";
import WhiteNoiseGif from "../../resources/white_noise_gif.gif";
import BetLightON from "../../resources/BetLightON.png";
import BetLightOFF from "../../resources/BetLightOFF.png";
import LightingRight from "../../resources/LightingRightFixed.png";
import LightingLeft from "../../resources/LightingLeft.png";
import Kalli from "../../resources/KalliFinal.gif";
import Cat from "../../resources/cat.gif";
import Critter from "../../resources/Critter.png";
import OverShadows from "../../resources/OverShadows.png";
import BetButtonGlow from "../../resources/BetButtonGlow.png";
import DrawButtonGlow from "../../resources/DrawButtonGlow.png";
import Exit from "../../resources/Exit.png";
import ExitOn from "../../resources/ExitOn.png";

const MatchView = () => {
  const history = useHistory();
  const socket = useSelector(({ socket }) => socket);
  const session = useSelector(({ session }) => session);

  const [message, setMessage] = useState("");
  const [players, setPlayers] = useState([]);
  const [messages, setMessages] = useState([]);
  const [isStarted, setIsStarted] = useState("");
  const [matchHost, setMatchHost] = useState("");
  const [matchDeck, setMatchDeck] = useState([]);
  const [matchWinner, setMatchWinner] = useState("");
  const [hurdleCards, setHurdleCards] = useState([]);
  const [recentCards, setRecentCards] = useState([]);
  const [matchEnded, setMatchEnded] = useState(false);
  const [sortPositioning, setSortPositioning] = useState({});
  const [hurdleCardCount, setHurdleCardCount] = useState(0);
  const [sipsGivenOut, setSipsGivenOut] = useState(true);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [canGiveSips, setCanGiveSips] = useState(false);
  const [isAllReady, setIsAllReady] = useState(false);
  const [showGiveSipModal, setShowGiveSipModal] = useState(false);
  const [showBetModal, setShowBetModal] = useState(true);
  const [hasPlacedBet, setHasPlacedBet] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [playersToSip, setPlayersToSip] = useState([]);
  const [sipsToGiveAmount, setSipsToGiveAmount] = useState(0);
  const [sipsGiven, setSipsGiven] = useState(0);
  const [cardChosen, setCardChosen] = useState("");
  const [sortChosen, setSortChosen] = useState("");
  const [amountChosen, setAmountChosen] = useState(0);
  const [hasChosenRandomCard, setHasChosenRandomCard] = useState(false);
  const [tabletUp, setTabletUp] = useState(false);
  const [phoneUp, setPhoneUp] = useState(false);
  const [showToolTip, setShowTooltip] = useState(false);
  /* Lighting variables */
  const [leftLightOn, setLeftLightOn] = useState(true);
  const [rightLightOn, setRightLightOn] = useState(true);
  const [lightTimer, setLightTimer] = useState(0);
  const [leftLightToggleTime, setLeftLightToggleTime] = useState([]);
  const [rightLightToggleTime, setRightLightToggleTime] = useState([]);
  const [showLeaveButtonGlow, setShowLeaveButtonGlow] = useState(false);

  const fullDeck = [
    "2C",
    "2D",
    "2H",
    "2S",
    "3C",
    "3D",
    "3H",
    "3S",
    "4C",
    "4D",
    "4H",
    "4S",
    "5C",
    "5D",
    "5H",
    "5S",
    "6C",
    "6D",
    "6H",
    "6S",
    "7C",
    "7D",
    "7H",
    "7S",
    "8C",
    "8D",
    "8H",
    "8S",
    "9C",
    "9D",
    "9H",
    "9S",
    "10C",
    "10D",
    "10H",
    "10S",
    "JC",
    "JD",
    "JH",
    "JS",
    "QC",
    "QD",
    "QH",
    "QS",
    "KC",
    "KD",
    "KH",
    "KS",
  ];

  const { id } = useParams();
  useEffect(() => {
    socket.emit("players", id);
    socket.emit("match", id);

    socket.on("match", (match) => {
      setHurdleCards(match.hurdleCards);
      setRecentCards(match.recentCards);
      setMatchHost(match.host);
      setMatchDeck(match.matchDeck);
      setIsStarted(match.isStarted);
      setHurdleCardCount(match.hurdleCardCount);
      setSortPositioning(match.sortPositioning);
      setSipsGivenOut(match.sipsGivenOut);
    });
    socket.on("players", (players) => {
      setPlayers(players);
    });
    socket.on("connected_user", (player) =>
      setPlayers((players) => {
        if (players.find((p) => p.userID === player.userID)) {
          players.find((p) => p.userID === player.userID).connected = true;
          return [...players];
        } else {
          return [...players, player];
        }
      })
    );
    socket.on("start_game", () => {
      setIsStarted(true);
    });

    socket.on("game_move", (matchId) => {
      socket.emit("match", matchId);
    });
    socket.on("match_ended", (matchId, winner, sortName) => {
      setIsAllReady(false);
      setIsStarted(false);
      setMatchEnded(true);
      setMatchWinner(winner);
      socket.emit("match_ended", matchId, winner, sortName);
    });

    socket.on("message_sent", (message) => {
      setMessages((messages) => [...messages, message]);
    });

    socket.on("reset_game", (matchId) => {
      setIsStarted(false);
      setMatchWinner("");
      setMatchEnded(false);
      setAmountChosen(0);
      setSortChosen("");
      setHasChosenRandomCard(false);
      setIsReady(false);
      socket.emit("reset_game", matchId);
    });

    socket.on("game_ready", (matchId) => {
      setIsAllReady(true);
      socket.emit("start_game", matchId);
    });

    socket.on("winner", (amount, playerList) => {
      const playersToSipList = [];
      playerList.map((p) =>
        playersToSipList.push({
          userId: p.userID,
          username: p.username,
          sips: 0,
        })
      );
      setCanGiveSips(true);
      setSipsToGiveAmount(amount * 2);
      setPlayersToSip(playersToSipList);
    });

    return () => {
      socket.off("players");
      socket.off("match");
      socket.off("connected_user");
      socket.off("start_game");
      socket.off("match_ended");
      socket.off("game_move");
      socket.off("message_sent");
      socket.off("user_left");
      socket.off("disconnected_user");
      socket.off("game_ready");
      socket.off("winner");
      socket.off("reset_game");
    };
  }, [id, socket]);

  const onUnload = (e) => {
    e.preventDefault();
    localStorage.removeItem("s.id", socket.sessionID);
  };

  useEffect(() => {
    window.addEventListener("beforeunload", onUnload);
  }, []);

  useEffect(() => {
    getLightToggleTimes();
    setInterval(() => {
      setLightTimer((prevTime) => prevTime + 1);
    }, 1000);
  }, []);

  useEffect(() => {
    if (leftLightToggleTime.indexOf(lightTimer) !== -1) {
      setLeftLightOn(false);
      var interval1 = getRandomInt(1, 1000);
      setTimeout(() => {
        setLeftLightOn(true);
      }, interval1);
    }
    if (rightLightToggleTime.indexOf(lightTimer) !== -1) {
      setRightLightOn(false);
      var interval2 = getRandomInt(1, 1000);
      setTimeout(() => {
        setRightLightOn(true);
      }, interval2);
    }
    if (lightTimer >= 60) {
      setLightTimer(0);
      setRightLightToggleTime([]);
      setLeftLightToggleTime([]);
      getLightToggleTimes();
    }
  }, [leftLightToggleTime, lightTimer, rightLightToggleTime, lightTimer]);

  const getLightToggleTimes = () => {
    for (var i = 0; i < 3; i++) {
      const rInt1 = getRandomInt(1, 60);
      const rInt2 = getRandomInt(1, 60);
      setRightLightToggleTime((rightLightToggleTime) => [
        ...rightLightToggleTime,
        rInt1,
      ]);
      setLeftLightToggleTime((leftLightToggleTime) => [
        ...leftLightToggleTime,
        rInt2,
      ]);
    }
  };

  const leaveGame = () => {
    localStorage.removeItem("s.id", socket.sessionID);
    socket.emit("leave");
    history.push("/");
  };

  const drawCard = () => {
    socket.emit("game_move", id, false);
  };

  const sendMessage = () => {
    socket.emit("send_message", message, id, session.username);
    setMessage("");
  };

  const disableButton = (e) => {
    e.preventDefault();
    setIsButtonDisabled(true);
    setTimeout(() => setIsButtonDisabled(false), 1000);
  };

  const confirmSips = () => {
    setCanGiveSips(false);
    setSipsGiven(0);
    setSipsToGiveAmount(0);
    setPlayersToSip([]);
    socket.emit("give_sip", id, session.username, playersToSip);
  };

  const updateAmountChosen = (val) => {
    if (amountChosen + val >= 0) {
      setAmountChosen((amountChosen) => amountChosen + val);
    }
  };

  const updatePlayerSipCount = (userId, amount) => {
    if (
      amount <= sipsToGiveAmount &&
      sipsGiven + amount <= sipsToGiveAmount &&
      amount + sipsGiven >= 0
    ) {
      const newPlayerList = playersToSip;
      const playerToUpdate = newPlayerList.findIndex(
        (p) => p.userId === userId
      );
      if (playerToUpdate !== -1) {
        if (newPlayerList[playerToUpdate].sips + amount >= 0) {
          setSipsGiven(sipsGiven + amount);
          newPlayerList[playerToUpdate] = {
            ...newPlayerList[playerToUpdate],
            sips: newPlayerList[playerToUpdate].sips + amount,
          };
        }
      }
      setPlayersToSip(newPlayerList);
    }
  };

  const placeBet = () => {
    socket.emit("ready", id, amountChosen, sortChosen);
    setIsReady(true);
  };

  const getRandomCard = () => {
    const card = fullDeck[Math.floor(Math.random() * fullDeck.length)];
    const cardLength = card.length;
    const cardSort = card[cardLength - 1];
    const cardNumber = card.slice(0, cardLength - 1);
    if (cardNumber === "K") {
      setAmountChosen(13);
    } else if (cardNumber === "Q") {
      setAmountChosen(12);
    } else if (cardNumber === "J") {
      setAmountChosen(11);
    } else if (cardNumber === "A") {
      setAmountChosen(1);
    } else {
      setAmountChosen(parseInt(cardNumber));
    }
    setSortChosen(cardSort);
    setCardChosen(card);
  };

  const getRandomInt = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const tooltipToggle = () => {
    setShowTooltip(true);
    setTimeout(() => {
      setShowTooltip(false);
    }, 2000);
  };

  return (
    <div className="match-container">
      <div className="button-highlight"></div>
      {showToolTip && <div className="tooltip">Copied to clipboard!</div>}
      <div
        className="copy"
        onClick={() => {
          navigator.clipboard.writeText(
            `https://horserace.eiki.is/?room=${id}`
          );
          tooltipToggle();
        }}
      ></div>
      {/* RESOURCES */}
      <img src={Cat} className="cat" alt="Cat" />
      <img src={Critter} className="critter" alt="Critter" />
      <img src={OverShadows} className="shadows" alt="Shadows" />
      <img src={Background} className="main-background" alt="Background" />
      <img src={Front} className="front" alt="Front" />
      <img src={Kalli} className="kalli" alt="Kalli the shopkeeper" />
      <img src={GameBoard} className="game-board-background" alt="Gameboard" />
      <img src={Exit} className="exit" alt="Exit" />
      {showLeaveButtonGlow && (
        <img src={ExitOn} className="exit-on" alt="Exit glowing" />
      )}
      {!isReady && (
        <img
          src={BetButtonGlow}
          className="button-glow"
          alt="Glowing bet button"
        />
      )}
      {matchHost === session.username && isAllReady && (
        <img
          src={DrawButtonGlow}
          className="button-glow"
          alt="Glowing draw button"
        />
      )}
      {leftLightOn && (
        <img
          src={LightingLeft}
          className="lighting"
          alt="Left side of lights"
        />
      )}
      {rightLightOn && (
        <img
          src={LightingRight}
          className="lighting"
          alt="Right side of lights"
        />
      )}
      <img className="white-noise" src={WhiteNoiseGif} alt="White noise" />
      {isReady ? (
        <img src={BetLightOFF} className="bet-light" alt="Bet light off" />
      ) : (
        <img src={BetLightON} className="bet-light" alt="Bet light on" />
      )}
      {/* END OF RESOURCES */}
      <Navigation
        matchId={id}
        hostName={matchHost}
        playerName={session.username}
        playerCount={players.length}
      />
      <RecentCards recentCards={recentCards} />
      <Match
        hurdleCards={hurdleCards}
        hurdleCardCount={hurdleCardCount}
        sortPositioning={sortPositioning}
      />
      <Phone
        message={message}
        messages={messages}
        phoneUp={phoneUp}
        sendMessage={() => sendMessage()}
        setMessage={(val) => setMessage(val)}
        setPhoneUp={() => setPhoneUp(!phoneUp)}
      />
      <Tablet
        players={players}
        isReady={isReady}
        tabletUp={tabletUp}
        hostName={matchHost}
        sipsGiven={sipsGiven}
        cardChosen={cardChosen}
        sortChosen={sortChosen}
        canGiveSips={canGiveSips}
        matchWinner={matchWinner}
        amountChosen={amountChosen}
        playersToSip={playersToSip}
        sipsGivenOut={sipsGivenOut}
        sipsToGiveAmount={sipsToGiveAmount}
        hasChosenRandomCard={hasChosenRandomCard}
        placeBet={() => placeBet()}
        leaveGame={() => leaveGame()}
        confirmSips={() => confirmSips()}
        getRandomCard={() => getRandomCard()}
        setShowBetModal={() => setShowBetModal()}
        setTabletUp={() => setTabletUp(!tabletUp)}
        setSortChosen={(sort) => setSortChosen(sort)}
        updateAmountChosen={(val) => updateAmountChosen(val)}
        setHasChosenRandomCard={() => setHasChosenRandomCard(true)}
        updatePlayerSipCount={(id, amount) => updatePlayerSipCount(id, amount)}
      />
      {isReady ? (
        matchHost === session.username &&
        isAllReady && (
          <button
            onClick={(e) => {
              drawCard();
              disableButton(e);
            }}
            disabled={isButtonDisabled ? true : false}
            className="draw-button"
          ></button>
        )
      ) : (
        <button
          onClick={() => setShowBetModal(true)}
          className="bet-button"
        ></button>
      )}
      <button
        onClick={() => leaveGame()}
        className="leave-button"
        onMouseEnter={() => setShowLeaveButtonGlow(true)}
        onMouseLeave={() => setShowLeaveButtonGlow(false)}
      ></button>
    </div>
  );
};

export default MatchView;
