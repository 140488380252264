import TabletImage from "../../resources/Tablet.png";
import Players from "../Players";
import WinnerModal from "../WinnerModal";
import TabletFingerprints from "../../resources/TabletFingerprints.png";
import "./styles.css";

const Tablet = ({
  updateAmountChosen,
  canGiveSips,
  matchWinner,
  sipsGivenOut,
  sipsGiven,
  playersToSip,
  sipsToGiveAmount,
  confirmSips,
  updatePlayerSipCount,
  players,
  hostName,
  leaveGame,
  isReady,
  setTabletUp,
  tabletUp,
  sortChosen,
  cardChosen,
  amountChosen,
  hasChosenRandomCard,
  placeBet,
  getRandomCard,
  setSortChosen,
  setShowBetModal,
  setHasChosenRandomCard,
}) => {
  return (
    <div className={"tablet " + (tabletUp ? "tablet-up" : "tablet-down")}>
      <img
        src={TabletImage}
        className="tablet-image"
        onClick={() => setTabletUp()}
        alt="Tablet"
      />
      <img src={TabletFingerprints} className="tablet-fingerprints" />
      <div className="tablet-content">
        {!isReady ? (
          <>
            <div className="modal-section">
              <span className="tablet-title">
                Select a sort and input drink amount
              </span>
              <div className="select-sort">
                <img
                  src={process.env.PUBLIC_URL + `/cards/PNG/AH.png`}
                  className={
                    "modal-ace " + (sortChosen === "H" ? "ace-selected" : "")
                  }
                  onClick={() => setSortChosen("H")}
                  alt="Ace of hearts"
                />
                <img
                  src={process.env.PUBLIC_URL + `/cards/PNG/AS.png`}
                  className={
                    "modal-ace " + (sortChosen === "S" ? "ace-selected" : "")
                  }
                  onClick={() => setSortChosen("S")}
                  alt="Ace of spades"
                />
                <img
                  src={process.env.PUBLIC_URL + `/cards/PNG/AD.png`}
                  className={
                    "modal-ace " + (sortChosen === "D" ? "ace-selected" : "")
                  }
                  onClick={() => setSortChosen("D")}
                  alt="Ace of diamonds"
                />
                <img
                  src={process.env.PUBLIC_URL + `/cards/PNG/AC.png`}
                  className={
                    "modal-ace " + (sortChosen === "C" ? "ace-selected" : "")
                  }
                  onClick={() => setSortChosen("C")}
                  alt="Ace of clubs"
                />
              </div>
              <div className="bet-amount-input">
                <button
                  className="sip-button"
                  onClick={() => updateAmountChosen(-1)}
                >
                  -1
                </button>
                <button
                  className="sip-button"
                  onClick={() => updateAmountChosen(-5)}
                >
                  -5
                </button>
                <button
                  className="sip-button"
                  onClick={() => updateAmountChosen(-10)}
                >
                  -10
                </button>
                <span className="tablet-title">({amountChosen})</span>
                <button
                  className="sip-button"
                  onClick={() => updateAmountChosen(1)}
                >
                  +1
                </button>
                <button
                  className="sip-button"
                  onClick={() => updateAmountChosen(5)}
                >
                  +5
                </button>
                <button
                  className="sip-button"
                  onClick={() => updateAmountChosen(10)}
                >
                  +10
                </button>
              </div>
            </div>
            <div className="separator">OR</div>
            <div className="modal-section">
              <span className="tablet-title">Get random card</span>
              {hasChosenRandomCard ? (
                <img
                  src={process.env.PUBLIC_URL + `/cards/PNG/${cardChosen}.png`}
                  className={
                    "modal-random-card " +
                    (hasChosenRandomCard ? "glowing" : "")
                  }
                  alt="Random card"
                />
              ) : (
                <img
                  src={process.env.PUBLIC_URL + "/cards/CardBack.png"}
                  className="modal-random-card"
                  onClick={() => {
                    setHasChosenRandomCard(true);
                    getRandomCard();
                  }}
                  alt="Card back"
                />
              )}
            </div>
            <button
              className="confirm-bet-button"
              onClick={() => {
                placeBet();
                setShowBetModal(false);
              }}
              disabled={amountChosen == 0 || sortChosen == ""}
            >
              Confirm bet
            </button>
          </>
        ) : !sipsGivenOut ? (
          canGiveSips ? (
            <WinnerModal
              sipsGiven={sipsGiven}
              playersToSip={playersToSip}
              sipsToGiveAmount={sipsToGiveAmount}
              confirmSips={() => confirmSips()}
              updatePlayerSipCount={(id, amount) =>
                updatePlayerSipCount(id, amount)
              }
            />
          ) : (
            <div className="lost-screen">
              <span className="tablet-title">You lost!</span>
              <span className="tablet-title">
                Waiting for drinks to be distributed...
              </span>
              <span className="tablet-title">Here is the sort that won:</span>
              <img
                src={process.env.PUBLIC_URL + `/cards/PNG/A${matchWinner}.png`}
                alt="Winner card"
              />
            </div>
          )
        ) : (
          <Players
            players={players}
            hostName={hostName}
            leaveGame={() => leaveGame()}
          />
        )}
      </div>
    </div>
  );
};

export default Tablet;
