import { useState } from 'react';
import './styles.css';

const Navigation = ({matchId}) => {
  return (
    <div className="navigation">
      <div className="room-code">
        <span className="room-code-text">ROOM CODE: {matchId}</span>
        </div>
    </div>
  )
}

export default Navigation;